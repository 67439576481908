import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import React from 'react';
import { DocumentIcon } from '../icons/icons';

import DataBlobsBox from './DataBlobsBox';

export default function DataProcessingView() {
  return (
    <Box style={{ height: '93vh', overflowY: 'auto' }}>
      <Card>
        <CardHeader
          avatar={<DocumentIcon color="primary" />}
          title={<Typography variant="h3">Your Data</Typography>}
          subheader="This page shows all your blob data in the cloud."
        />
        <CardContent>
          <DataBlobsBox />
        </CardContent>
      </Card>
    </Box>
  );
};
