import React from "react"
import SubscriptionUpdates from "../../components/activity/subscription-updates"
import SEO from "../../components/common/seo"
import DataDashboardView from "../../components/data-dashboard/DataDashboardView"
import Paywall from "../../components/paywall/paywall"

const DataDashboardPage = () => {
  return (
    <>
      <SEO title="NavAbility Data Dashboard" />
      <Paywall>
        <DataDashboardView />
        <SubscriptionUpdates />
      </Paywall>
    </>
  )
}

export default DataDashboardPage;
